<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title>
        {{$t('erp.lang_garnishGroupAdd')}}
      </v-card-title>
      <v-divider class="ma-0"/>
      <v-card-text>
        <v-form v-model="valid" ref="form" onsubmit="return false">
          <v-row justify-md="center">
            <v-col cols="12" md="5" lg="4" sm="6">
              <v-text-field v-model="name"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="$t('erp.lang_garnishGroupName')"
                            autocomplete="off"
                            :rules="[v=>!!v && v.length>0 || $t('generic.lang_requiredField')]"
                            outlined
                            required
                            @focus="showTouchKeyboard"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5" lg="4" sm="6">
              <fontawesome-picker :fieldLabel="'Icon'" v-model="icon"/>
            </v-col>
            <v-col cols="12" md="10" lg="4" sm="12">
              <div class="widget-content text-left pa-0">
                <span class="font-weight-medium">{{ $t('erp.lang_warecreate_color') }}:</span>
                <swatches
                    v-model="color" inline
                    background-color="transparent"
                ></swatches>
              </div>
            </v-col>
            <v-col cols="12" align="end">
              <v-btn @click="create()" :disabled="!valid" :loading="loading" >
                {{$t('generic.lang_create')}}
              </v-btn>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events"
import mixin from "@/mixins/KeyboardMixIns"
import FontawesomePicker from "@/components/common/iconPicker";
import Swatches from 'vue-swatches';

export default {
  name: "CreateGarnishGroupComponent",
  components:{FontawesomePicker,Swatches},
  mixins:[mixin],
  data(){
    return {
      valid:false,
      color:null,
      name:null,
      icon:null,
      loading:false,
    }
  },
  methods:{
    create(){
      if (!this.$refs.form.validate()) return;
      let form = {};
      form.name = this.name;
      form.color = this.color;
      form.icon = this.icon;
      this.loading = true;

      this.axios.post(ENDPOINTS.ERP.GARNISHGROUP.CREATE, form).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_garnishGroupCreated'),
            color: "success"
          });
          this.$refs.form.reset()
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: res.data.msg || res.data.status
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>